// Style File include
import "./mvox_blur.css";

function MvocBlurView(props) {
  return (
    <>
      <div className="mvoc-bg-section blur-background">
        <div className="d-flex align-items-center mb-4">
          <div className="form-group w-100 pr-1">
            <label htmlFor="assetName" className="input-label">
              Asset Name <sup className="red-color">*</sup>
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group w-100 pl-1 pr-1">
            <label htmlFor="indicationName" className="input-label">
              Indication Name <sup className="red-color">*</sup>
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group w-100 pl-1">
            <label htmlFor="manufacturerName" className="input-label">
              Manufacturer Name <sup className="red-color">*</sup>
            </label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="d-flex w-100 mb-3">
          <div className="mvoc-drop-down-wd">
            <div className="d-flex align-items-center position-relative hgt-30p">
              <p className="upload-mvoc-data-ttl">
                Upload Voice of Customer(MVOC) data file
              </p>
            </div>
            <div className={`drop-zone drop-zone-section`}>
              <svg
                width="66"
                height="65"
                viewBox="0 0 66 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M33.4551 64.5C51.1282 64.5 65.4551 50.1731 65.4551 32.5C65.4551 14.8269 51.1282 0.5 33.4551 0.5C15.782 0.5 1.45508 14.8269 1.45508 32.5C1.45508 50.1731 15.782 64.5 33.4551 64.5Z"
                  stroke="#451C86"
                />
                <path
                  d="M33.3554 58.8119C47.7363 58.8119 59.3944 47.1538 59.3944 32.7729C59.3944 18.3919 47.7363 6.73389 33.3554 6.73389C18.9745 6.73389 7.31641 18.3919 7.31641 32.7729C7.31641 47.1538 18.9745 58.8119 33.3554 58.8119Z"
                  stroke="#4D258B"
                  strokeWidth="3"
                />
                <path
                  d="M33.3564 54.9399C45.5984 54.9399 55.5224 45.0159 55.5224 32.7739C55.5224 20.532 45.5984 10.6079 33.3564 10.6079C21.1145 10.6079 11.1904 20.532 11.1904 32.7739C11.1904 45.0159 21.1145 54.9399 33.3564 54.9399Z"
                  fill="#4D258B"
                />
                <path
                  d="M41.9049 30.0089L34.2459 20.6859C34.1186 20.5304 33.9584 20.4051 33.7768 20.3191C33.5952 20.2331 33.3968 20.1885 33.1959 20.1885C32.9949 20.1885 32.7965 20.2331 32.6149 20.3191C32.4334 20.4051 32.2731 20.5304 32.1459 20.6859L24.4889 30.0089C24.3246 30.2076 24.2204 30.449 24.1883 30.7048C24.1562 30.9606 24.1976 31.2202 24.3076 31.4534C24.4176 31.6865 24.5917 31.8836 24.8096 32.0214C25.0275 32.1593 25.2801 32.2323 25.5379 32.2319H29.3899V44.3399C29.3893 44.5181 29.4241 44.6946 29.492 44.8594C29.56 45.0242 29.6598 45.1738 29.7859 45.2999C29.9119 45.4259 30.0616 45.5258 30.2263 45.5937C30.3911 45.6617 30.5676 45.6964 30.7459 45.6959H35.6459C35.8243 45.6966 36.001 45.6621 36.166 45.5943C36.331 45.5264 36.4809 45.4266 36.6072 45.3006C36.7334 45.1745 36.8335 45.0247 36.9015 44.8599C36.9696 44.695 37.0044 44.5182 37.0039 44.3399V32.2399H40.8559C41.1151 32.2415 41.3694 32.169 41.5888 32.0307C41.8081 31.8925 41.9833 31.6943 42.0936 31.4597C42.2039 31.2251 42.2448 30.9638 42.2113 30.7067C42.1779 30.4496 42.0715 30.2074 41.9049 30.0089Z"
                  fill="#F0F2F8"
                />
              </svg>
              <p className="drag-and-drop-text">Drag &amp; Drop</p>
              <div className="d-flex align-items-center">
                <label className="custom-file-upload">
                  <div className="d-flex flex-column align-items-center">
                    <button className={"btn btn-sm btn-upload-file mt-3"}>
                      <span>Upload</span>
                    </button>
                  </div>
                  <div className="text-center mt-2">
                    <div className="d-flex align-items-center justify-content-center mb-3">
                      <p className="your-file-text">Your file or&nbsp;</p>
                      <p className="browser-text">Browse</p>
                    </div>
                    <p className="upload-text-small">
                      MVOC file should have at least one column with header{" "}
                      <b>“conversation_column”</b>.
                    </p>
                  </div>
                </label>
              </div>
            </div>
            <p className="download-sample-data">
              <a>Click here</a> to download the sample template file.
            </p>
          </div>
          <div className="mvoc-drop-down-wd">
            <div className="d-flex align-items-center position-relative hgt-30p">
              <p className="upload-si-mapping-data-ttl">
                Upload the themes to Strategic Imperatives(SI) Mapping
              </p>
              <button className={`on toggle-button position-relative`}>
                <span className="toggle-yes">{`Yes`}</span>
                <span className="pin" />
              </button>
            </div>
            <div className={`drop-zone drop-zone-section`}>
              <svg
                width="66"
                height="65"
                viewBox="0 0 66 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M33.4551 64.5C51.1282 64.5 65.4551 50.1731 65.4551 32.5C65.4551 14.8269 51.1282 0.5 33.4551 0.5C15.782 0.5 1.45508 14.8269 1.45508 32.5C1.45508 50.1731 15.782 64.5 33.4551 64.5Z"
                  stroke="#451C86"
                />
                <path
                  d="M33.3554 58.8119C47.7363 58.8119 59.3944 47.1538 59.3944 32.7729C59.3944 18.3919 47.7363 6.73389 33.3554 6.73389C18.9745 6.73389 7.31641 18.3919 7.31641 32.7729C7.31641 47.1538 18.9745 58.8119 33.3554 58.8119Z"
                  stroke="#4D258B"
                  strokeWidth="3"
                />
                <path
                  d="M33.3564 54.9399C45.5984 54.9399 55.5224 45.0159 55.5224 32.7739C55.5224 20.532 45.5984 10.6079 33.3564 10.6079C21.1145 10.6079 11.1904 20.532 11.1904 32.7739C11.1904 45.0159 21.1145 54.9399 33.3564 54.9399Z"
                  fill="#4D258B"
                />
                <path
                  d="M41.9049 30.0089L34.2459 20.6859C34.1186 20.5304 33.9584 20.4051 33.7768 20.3191C33.5952 20.2331 33.3968 20.1885 33.1959 20.1885C32.9949 20.1885 32.7965 20.2331 32.6149 20.3191C32.4334 20.4051 32.2731 20.5304 32.1459 20.6859L24.4889 30.0089C24.3246 30.2076 24.2204 30.449 24.1883 30.7048C24.1562 30.9606 24.1976 31.2202 24.3076 31.4534C24.4176 31.6865 24.5917 31.8836 24.8096 32.0214C25.0275 32.1593 25.2801 32.2323 25.5379 32.2319H29.3899V44.3399C29.3893 44.5181 29.4241 44.6946 29.492 44.8594C29.56 45.0242 29.6598 45.1738 29.7859 45.2999C29.9119 45.4259 30.0616 45.5258 30.2263 45.5937C30.3911 45.6617 30.5676 45.6964 30.7459 45.6959H35.6459C35.8243 45.6966 36.001 45.6621 36.166 45.5943C36.331 45.5264 36.4809 45.4266 36.6072 45.3006C36.7334 45.1745 36.8335 45.0247 36.9015 44.8599C36.9696 44.695 37.0044 44.5182 37.0039 44.3399V32.2399H40.8559C41.1151 32.2415 41.3694 32.169 41.5888 32.0307C41.8081 31.8925 41.9833 31.6943 42.0936 31.4597C42.2039 31.2251 42.2448 30.9638 42.2113 30.7067C42.1779 30.4496 42.0715 30.2074 41.9049 30.0089Z"
                  fill="#F0F2F8"
                />
              </svg>
              <p className="drag-and-drop-text">Drag &amp; Drop</p>
              <div className="d-flex align-items-center">
                <label className="custom-file-upload">
                  <div className="d-flex flex-column align-items-center">
                    <button className={"btn btn-sm btn-upload-file mt-3"}>
                      <span>Upload</span>
                    </button>
                  </div>
                  <div className="text-center mt-2">
                    <div className="d-flex align-items-center justify-content-center mb-3">
                      <p className="your-file-text">Your file or&nbsp;</p>
                      <p className="browser-text">Browse</p>
                    </div>
                    <p className="upload-text-small">
                      MVOC file should have at least one column with header{" "}
                      <b>“conversation_column”</b>.
                    </p>
                  </div>
                </label>
              </div>
            </div>
            <p className="download-sample-data">
              <a>Click here</a> to download the sample template file.
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <button className={`btn btn-sm btn-submit disabled`}>
            <i className="bi bi-floppy"></i>
            <span className="ml-1">Submit</span>
          </button>
        </div>
        <div className="center-msg">
          <div className="login-details-bg">
            <h3>
              <span className="">
                {props?.userLoginData?.firstRankusername
                  .split(".")
                  .join(" ")
                  .replace(/\b\w/g, (c) => c.toUpperCase())}
              </span>
              {`, user already using this application.`}
            </h3>
            <p>Please close the window &amp; come back after some time !!</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default MvocBlurView;
