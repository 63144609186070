import React from "react";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";

function ProtectedRoute({ element }) {
  const [cookies] = useCookies(["inizionavigator"]);
  const userName =
    cookies.inizionavigator && cookies.inizionavigator.userLoginName;
  const token = cookies.inizionavigator && cookies.inizionavigator.token;

  const isAuthenticated = () => {
    return token !== null || userName !== null;
  };

  return isAuthenticated() ? element : <Navigate to="/" replace={true} />;
}

export default ProtectedRoute;
