import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "react-bootstrap";
import ApiLoader from "./ApiLoader";
import * as XLSX from "xlsx";

// Import download Samle file's for Download
import MvocSampleFile from "../../assets/download/sample_mvoc.xlsx";
import SiMappingSampleFile from "../../assets/download/sample_si_mapping.xlsx";

// Style File include
import "./mvoc_primary.css";

// Service File include
import {  fetchChatGptProcessService, uploadFileServiceApi } from "../../services/homePageService";

function MvocPrimary() {
  let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
 
  // console.log(apiBaseUrl);

  // use for navigate app url
  const navigate = useNavigate();

  // Blob Folder Name
  const userBlobFolderName = localStorage.getItem("blobFolder");

  // Define state for API loading state to check api is complete or not
  const [apiLoading, setApiLoading] = useState(false);
  const [apiLoaderInfo, setaApiLoaderInfo] = useState(false);

  // Define state for chatGPT API loading, is comeplete or false
  const [oopsLoader, setOopsLoader] = useState(false);

  // Define state for Submit button to activate after all files are upload
  const [submitBtnActive, setSubmitBtnActive] = useState(false);

  // Define state for SASS Token
  const [sasTokenfileNameMvoc, setSasTokenfileNameMvoc] = useState("");
  const [sasTokenfileNameSiMapping, setSasTokenfileNameSiMapping] =
    useState("");

  // MVOC PRIMARY theme asset, indication and manufactutor name state's
  const [assetName, setAssetName] = useState("");
  const [indicationName, setIndicationName] = useState("");
  const [manufacturerName, setManufacturerName] = useState("");

  // Toast popup message for upload and error show
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  // Define state for Toggle Button active or disable
  const [isActive, setIsActive] = useState(true);

  // Hide icon of upload, when user upload file
  const [hideDragDropOfMvoc, setHideDragDropOfMvoc] = useState(false);
  const [hideDragDropOfSi, setHideDragDropOfSi] = useState(false);

  // MVOC FILE upload and validation
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [csvContentError, setCsvContentError] = useState("");
  const [isSubmitDisabledMvoc, setIsSubmitDisabledMvoc] = useState(true);

  // SI Mapping file upload and Validation
  const [siMappingSelectedFile, setSiMappingSelectedFile] = useState(null);
  const [siMappingFileError, setSiMappingFileError] = useState("");
  const [siMappingCsvContentError, setSiMappingCsvContentError] = useState("");
  const [isSubmitDisabledSiMapping, setIsSubmitDisabledSiMapping] =
    useState(true);

  // Function to check if all required fields are filled
  const areAllFieldsFilled = () => {
    return (
      assetName.trim() !== "" &&
      indicationName.trim() !== "" &&
      manufacturerName.trim() !== ""
    );
  };

  // function for toggle button activation
  const toggle = () => {
    setIsActive(!isActive);
  };
  // Function to hide the success Toast after a certain time interval
  const hideSuccessToast = () => {
    setTimeout(() => {
      setShowSuccessToast(false);
    }, 1500);
  };
  // Function to hide the error Toast after a certain time interval
  const hideErrorToast = () => {
    setTimeout(() => {
      setShowErrorToast(false);
    }, 1500);
  };

  // Functons for upload file and validation in MVOC file upload option
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (!file) {
      setFileError("File selection was canceled.");
      setCsvContentError("");
      setIsSubmitDisabledMvoc(true);
      return;
    }

    if (file && !file.name.endsWith(".xlsx")) {
      setFileError("Please select a valid XLSX file");
      setCsvContentError("");
      setIsSubmitDisabledMvoc(true);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // To check if row count is more then 100, it disable submit button.
      const rowCount = parsedData.length;
      if (rowCount > 100) {
        // Error handling for exceeding row limit
        setFileError("The file exceeds the maximum row limit of 100.");
        setCsvContentError("");
        setIsSubmitDisabledMvoc(true);
        return;
      }

      // To check "conversation_column" is missing, upload button will be disable
      const headers = parsedData[0];
      if (!headers.includes("conversation_column")) {
        setFileError('The "conversation_column" is missing in the XLSX file');
        setCsvContentError("");
        setIsSubmitDisabledMvoc(true);
        return;
      }

      // Proceed with upload logic if the required column exists
      setFileError("");
      setCsvContentError("");
      setIsSubmitDisabledMvoc(false);
    };
    reader.readAsArrayBuffer(file);
    setFileError("");
  };

  const handleFileCancel = () => {
    setSelectedFile(null);
  };

  const generateFileName = () => {
    const timestamp = new Date().toISOString().replace(/[:.-]/g, ""); // Create a timestamp without special characters
    return `${assetName.split(" ").join("_")}_${indicationName.split(" ").join("_")}_${manufacturerName.split(" ").join("_")}_${timestamp}_mvoc.xlsx`;
  };

  const handleUpload = (event) => {
    if (selectedFile) {
      const allFieldsFilled = areAllFieldsFilled(); // Check if all input fields are filled
      if (!allFieldsFilled) {
        // Show toast message about missing input fields
        setShowErrorToast(true);
        setCsvContentError("Please fill all required fields.");
        hideErrorToast(); // Automatically hide the error Toast
        return; // Prevent further execution if fields are not filled
      } else {
        const fileName = generateFileName();
        uploadFile(selectedFile, fileName);
        setHideDragDropOfMvoc(true);
        setShowSuccessToast(true);
        setSasTokenfileNameMvoc(fileName);
        hideSuccessToast(); // Automatically hide the success Toast
        setSubmitBtnActive(true); // submit button active or de-active
        // const isButtonEnabled = isSubmitButtonEnabled(); // Check if submit button should be enabled
        // setSubmitBtnActive(isButtonEnabled); // Update submit button state
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
    if (file && !file.name.endsWith(".xlsx")) {
      setFileError("Please select a valid XLSX file.");
    } else {
      setFileError("");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // console.log(process.env);
  // console.log(process.env.REACT_APP_API_BLOB_STORAGE_END_POINTS);

  async function uploadFile(file, fileName) {
      const payload=new FormData();
      payload.append('fileName',fileName);
      payload.append('file',file);
    try {

      const response=await uploadFileServiceApi(payload);
  
      if (response.status == 201) {
        console.log("File uploaded successfully");
      } else {
        console.error("Failed to upload file:", response.statusText);
      }

    } catch (error) {
      console.error("error:", error);
    }

  }
  // #END

  // FUnctions to define SI Mapping file upload option and validation
  // Function to setup file name based on time stamp
  const generateSiMappingFileName = () => {
    const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
    return `${assetName.split(" ").join("_")}_${indicationName.split(" ").join("_")}_${manufacturerName.split(" ").join("_")}_${timestamp}_si_mapping.xlsx`;
  };

  // function for upload file SI Mapping
  const handleSiMappingFileChange = (e) => {
    const file = e.target.files[0];
    setSiMappingSelectedFile(file);

    if (!file) {
      setSiMappingFileError("File selection was canceled.");
      setSiMappingCsvContentError("");
      setIsSubmitDisabledSiMapping(true);
      return;
    }

    if (file && !file.name.endsWith(".xlsx")) {
      setSiMappingFileError("Please select a valid XLSX file");
      setSiMappingCsvContentError("");
      setIsSubmitDisabledSiMapping(true);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // To check if row count is more then 100, it disable submit button.
      const rowCount = parsedData.length;
      if (rowCount < 2) {
        setSiMappingFileError("The file should have at least one SI mapping.");
        setSiMappingCsvContentError("");
        setIsSubmitDisabledSiMapping(true);
        return;
      }

      const headers = parsedData[0];
      const requiredColumns = ["Primary_Themes", "SI"];
      const hasAllColumns = requiredColumns.every((column) =>
        headers.includes(column),
      );

      if (!hasAllColumns) {
        setSiMappingFileError(
          "The required columns are missing in the XLSX file",
        );
        setSiMappingCsvContentError("");
        setIsSubmitDisabledSiMapping(true);
        return;
      }

      // Proceed with upload logic if the required columns exist
      setSiMappingFileError("");
      setSiMappingCsvContentError("");
      setIsSubmitDisabledSiMapping(false);
    };
    reader.readAsArrayBuffer(file);
    setSiMappingFileError("");
  };

  const handleSiMappingFileCancel = () => {
    setSiMappingSelectedFile(null);
  };

  const handleSiMappingUpload = (event) => {
    if (siMappingSelectedFile) {
      const allFieldsFilled = areAllFieldsFilled(); // Check if all input fields are filled
      if (!allFieldsFilled) {
        // Show toast message about missing input fields
        setShowErrorToast(true);
        setCsvContentError("Please fill all required fields.");
        hideErrorToast(); // Automatically hide the error Toast
        return; // Prevent further execution if fields are not filled
      } else {
        const fileName = generateSiMappingFileName();
        uploadSiMappingFile(siMappingSelectedFile, fileName);
        setShowSuccessToast(true);
        setHideDragDropOfSi(true);
        setSasTokenfileNameSiMapping(fileName);

        hideSuccessToast(); // Automatically hide the success Toast
        // setSubmitBtnActive(true); // submit button active or de-active
        // const isButtonEnabled = isSubmitButtonEnabled(); // Check if submit button should be enabled
        // setSubmitBtnActive(isButtonEnabled); // Update submit button state
      }
    }
  };

  // Function to upload SI Mapping file
  async function uploadSiMappingFile(file, fileName) {
   
    const payload=new FormData();
    payload.append('fileName',fileName);
    payload.append('file',file);

    try {
      const response=await uploadFileServiceApi(payload);
      if (response.status == 201) {
        console.log("File uploaded successfully");
      } else {
        console.error("Failed to upload file:", response.statusText);
      }

    } catch (error) {
      console.log(error, "error");
    }
  }

  // Function to call API for process data
  const getDownloadableFile = async () => {
    

   // setApiLoading(true);

   // const fetchChatGPTProcessApiUrl = `${apiBaseUrl}/run_notebook?asset=${assetName.split(" ").join("_")}&indication=${indicationName.split(" ").join("_")}&manufacturer=${manufacturerName.split(" ").join("_")}&blobfolder=${userBlobFolderName}&filename_mvoc=${sasTokenfileNameMvoc}&filename_si=${isActive ? sasTokenfileNameSiMapping : "false"}`; // Replace YOUR_SAS_TOKEN with your actual SAS token

    // console.log("fetch download API : ", fetchChatGPTProcessApiUrl);

    // console.log('api url', fetchChatGPTProcessApiUrl);

    // fetch(fetchChatGPTProcessApiUrl, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Origin: "http://localhost:3000",
    //     "Access-Control-Request-Method": "GET",
    //     "Access-Control-Request-Headers": "Content-Type",
    //   },
    //   mode: "cors",
    //   credentials: "same-origin", // or 'include' if you need to send cookies
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! Status: ${response.status}`);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     // console.log('forword data ', data);
    //     // console.log("donwload file api loader info", apiLoaderInfo);
    //     setaApiLoaderInfo(true);
    //     setTimeout(() => {
    //       // console.log('process complete, redireact to download.');
    //       setApiLoading(false);
    //       navigate("/download", { state: data });
    //     }, 3000);
    //   })
    //   .catch((error) => {
    //     console.error("Fetch error:", error);
    //     setOopsLoader(true);
    //   });



      /* api implementation of run-note-book api of nodejs  in nodejs api change in 
      response json formate so there is also code change for keys and 
      arrayOfObjects in WheelChart.js both are there as commented code in case of
      nodejs run-not-book api need to update and uncomment that code  */


    setApiLoading(true);
    try {
      const payload={
        asset:assetName.split(" ").join("_"),
        indication:indicationName.split(" ").join("_"),
        manufacturer:manufacturerName.split(" ").join("_"),
        blobfolder:userBlobFolderName,
        filename_mvoc:sasTokenfileNameMvoc,
        filename_si:isActive ? sasTokenfileNameSiMapping : "false"
      };
      const response=await fetchChatGptProcessService(payload.asset,payload.indication,payload.manufacturer,payload.blobfolder,payload.filename_mvoc,payload.filename_si);

      if(response.status==200){
        const data=response?.data;
        setaApiLoaderInfo(true);
        setTimeout(() => {
          // console.log('process complete, redireact to download.');
          setApiLoading(false);
          navigate("/download", { state: data });
        }, 3000);
      }else{
        setOopsLoader(true);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setOopsLoader(true);
    }
  };

  // Function to check if the submit button should be enabled
  // const isSubmitButtonEnabled = () => {
  //     const isMvocFileUploaded = selectedFile && !fileError && !csvContentError;
  //     const isSiMappingFileUploaded = siMappingSelectedFile && !siMappingFileError && !siMappingCsvContentError;

  //     if (isMvocFileUploaded && isActive) {
  //         return isSiMappingFileUploaded;
  //     } else {
  //         return isMvocFileUploaded;
  //     }
  // };

  return (
    <div className="mvoc-bg-section toast-msg">
      <Toast
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        bg="success"
        className="position-fixed top-0 end-0 m-3"
      >
        <Toast.Body>Upload successful!</Toast.Body>
      </Toast>

      <Toast
        show={showErrorToast}
        onClose={() => setShowErrorToast(false)}
        bg="danger"
        className="position-fixed top-0 end-0 m-3"
      >
        <Toast.Body>{csvContentError}</Toast.Body>
      </Toast>

      <div className="d-flex align-items-center mb-4">
        <div className="form-group w-100 pr-1">
          <label htmlFor="assetName" className="input-label">
            Asset Name <sup className="red-color">*</sup>
          </label>
          <input
            type="text"
            className="form-control"
            value={assetName}
            onChange={(e) => setAssetName(e.target.value)}
          />
        </div>
        <div className="form-group w-100 pl-1 pr-1">
          <label htmlFor="indicationName" className="input-label">
            Indication Name <sup className="red-color">*</sup>
          </label>
          <input
            type="text"
            className="form-control"
            value={indicationName}
            onChange={(e) => setIndicationName(e.target.value)}
          />
        </div>
        <div className="form-group w-100 pl-1">
          <label htmlFor="manufacturerName" className="input-label">
            Manufacturer Name <sup className="red-color">*</sup>
          </label>
          <input
            type="text"
            className="form-control"
            value={manufacturerName}
            onChange={(e) => setManufacturerName(e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex w-100 mb-3">
        <div className="mvoc-drop-down-wd">
          <div className="d-flex align-items-center position-relative hgt-30p">
            <p className="upload-mvoc-data-ttl">
              Upload Voice of Customer(MVOC) data file
            </p>
          </div>
          <div
            className={`drop-zone drop-zone-section ${fileError ? "error" : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            {!hideDragDropOfMvoc && (
              <svg
                width="66"
                height="65"
                viewBox="0 0 66 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M33.4551 64.5C51.1282 64.5 65.4551 50.1731 65.4551 32.5C65.4551 14.8269 51.1282 0.5 33.4551 0.5C15.782 0.5 1.45508 14.8269 1.45508 32.5C1.45508 50.1731 15.782 64.5 33.4551 64.5Z"
                  stroke="#451C86"
                />
                <path
                  d="M33.3554 58.8119C47.7363 58.8119 59.3944 47.1538 59.3944 32.7729C59.3944 18.3919 47.7363 6.73389 33.3554 6.73389C18.9745 6.73389 7.31641 18.3919 7.31641 32.7729C7.31641 47.1538 18.9745 58.8119 33.3554 58.8119Z"
                  stroke="#4D258B"
                  strokeWidth="3"
                />
                <path
                  d="M33.3564 54.9399C45.5984 54.9399 55.5224 45.0159 55.5224 32.7739C55.5224 20.532 45.5984 10.6079 33.3564 10.6079C21.1145 10.6079 11.1904 20.532 11.1904 32.7739C11.1904 45.0159 21.1145 54.9399 33.3564 54.9399Z"
                  fill="#4D258B"
                />
                <path
                  d="M41.9049 30.0089L34.2459 20.6859C34.1186 20.5304 33.9584 20.4051 33.7768 20.3191C33.5952 20.2331 33.3968 20.1885 33.1959 20.1885C32.9949 20.1885 32.7965 20.2331 32.6149 20.3191C32.4334 20.4051 32.2731 20.5304 32.1459 20.6859L24.4889 30.0089C24.3246 30.2076 24.2204 30.449 24.1883 30.7048C24.1562 30.9606 24.1976 31.2202 24.3076 31.4534C24.4176 31.6865 24.5917 31.8836 24.8096 32.0214C25.0275 32.1593 25.2801 32.2323 25.5379 32.2319H29.3899V44.3399C29.3893 44.5181 29.4241 44.6946 29.492 44.8594C29.56 45.0242 29.6598 45.1738 29.7859 45.2999C29.9119 45.4259 30.0616 45.5258 30.2263 45.5937C30.3911 45.6617 30.5676 45.6964 30.7459 45.6959H35.6459C35.8243 45.6966 36.001 45.6621 36.166 45.5943C36.331 45.5264 36.4809 45.4266 36.6072 45.3006C36.7334 45.1745 36.8335 45.0247 36.9015 44.8599C36.9696 44.695 37.0044 44.5182 37.0039 44.3399V32.2399H40.8559C41.1151 32.2415 41.3694 32.169 41.5888 32.0307C41.8081 31.8925 41.9833 31.6943 42.0936 31.4597C42.2039 31.2251 42.2448 30.9638 42.2113 30.7067C42.1779 30.4496 42.0715 30.2074 41.9049 30.0089Z"
                  fill="#F0F2F8"
                />
              </svg>
            )}
            <p className="drag-and-drop-text">Drag &amp; Drop</p>
            <div className="d-flex align-items-center">
              <label className="custom-file-upload">
                {selectedFile ? (
                  <div className="d-flex flex-column align-items-center">
                    <div className="position-relative d-flex align-items-center mt-2">
                      <p className="browser-text">Change File</p>
                      <div className="cancel-upload" onClick={handleFileCancel}>
                        <i className="bi bi-x"></i>
                      </div>
                      <p className="selected-file-name">
                        {" "}
                        {selectedFile && selectedFile.name}
                      </p>
                    </div>
                    <button
                      onClick={handleUpload}
                      className={"btn btn-sm btn-upload-file mt-3"}
                      disabled={isSubmitDisabledMvoc}
                    >
                      <span>Upload</span>
                    </button>
                  </div>
                ) : (
                  <div className="text-center">
                    <div className="d-flex align-items-center justify-content-center mb-3">
                      <p className="your-file-text">Your file or&nbsp;</p>
                      <p className="browser-text">Browse</p>
                    </div>
                    <p className="upload-text-small">
                      MVOC file should have at least one column with header{" "}
                      <b>“conversation_column”</b>.
                    </p>
                    <p className="upload-text-extra-small">
                      Please restrict your input under 100 rows.
                    </p>
                  </div>
                )}
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={handleFileChange}
                  onBlur={handleFileCancel}
                />
              </label>
            </div>
            {fileError && <p className="error-message">{fileError}</p>}
          </div>
          <p className="download-sample-data">
            <a
              href={MvocSampleFile}
              download="Sample-MVOC"
              target="_blank"
              rel="noreferrer"
            >
              Click here
            </a>{" "}
            to download the sample template file.
          </p>
        </div>

        <div className="si-mapping-drop-down-wd pr-0">
          <div className="d-flex align-items-center position-relative hgt-30p">
            <p className="upload-si-mapping-data-ttl">
              Upload the themes to Strategic Imperatives(SI) Mapping
            </p>
            <button
              className={
                isActive
                  ? "on toggle-button position-relative"
                  : "off toggle-button"
              }
              onClick={toggle}
            >
              <span className="toggle-yes">{isActive ? "Yes" : ""}</span>
              <span className="pin" />
              <span className="toggle-no">{isActive ? "" : "No"}</span>
            </button>
          </div>

          <div
            className={`drop-zone drop-zone-section ${siMappingFileError ? "error" : ""} ${isActive ? "" : "disabled"}`}
          >
            {!hideDragDropOfSi && (
              <svg
                width="66"
                height="65"
                viewBox="0 0 66 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M33.4551 64.5C51.1282 64.5 65.4551 50.1731 65.4551 32.5C65.4551 14.8269 51.1282 0.5 33.4551 0.5C15.782 0.5 1.45508 14.8269 1.45508 32.5C1.45508 50.1731 15.782 64.5 33.4551 64.5Z"
                  stroke="#451C86"
                />
                <path
                  d="M33.3554 58.8119C47.7363 58.8119 59.3944 47.1538 59.3944 32.7729C59.3944 18.3919 47.7363 6.73389 33.3554 6.73389C18.9745 6.73389 7.31641 18.3919 7.31641 32.7729C7.31641 47.1538 18.9745 58.8119 33.3554 58.8119Z"
                  stroke="#4D258B"
                  strokeWidth="3"
                />
                <path
                  d="M33.3564 54.9399C45.5984 54.9399 55.5224 45.0159 55.5224 32.7739C55.5224 20.532 45.5984 10.6079 33.3564 10.6079C21.1145 10.6079 11.1904 20.532 11.1904 32.7739C11.1904 45.0159 21.1145 54.9399 33.3564 54.9399Z"
                  fill="#4D258B"
                />
                <path
                  d="M41.9049 30.0089L34.2459 20.6859C34.1186 20.5304 33.9584 20.4051 33.7768 20.3191C33.5952 20.2331 33.3968 20.1885 33.1959 20.1885C32.9949 20.1885 32.7965 20.2331 32.6149 20.3191C32.4334 20.4051 32.2731 20.5304 32.1459 20.6859L24.4889 30.0089C24.3246 30.2076 24.2204 30.449 24.1883 30.7048C24.1562 30.9606 24.1976 31.2202 24.3076 31.4534C24.4176 31.6865 24.5917 31.8836 24.8096 32.0214C25.0275 32.1593 25.2801 32.2323 25.5379 32.2319H29.3899V44.3399C29.3893 44.5181 29.4241 44.6946 29.492 44.8594C29.56 45.0242 29.6598 45.1738 29.7859 45.2999C29.9119 45.4259 30.0616 45.5258 30.2263 45.5937C30.3911 45.6617 30.5676 45.6964 30.7459 45.6959H35.6459C35.8243 45.6966 36.001 45.6621 36.166 45.5943C36.331 45.5264 36.4809 45.4266 36.6072 45.3006C36.7334 45.1745 36.8335 45.0247 36.9015 44.8599C36.9696 44.695 37.0044 44.5182 37.0039 44.3399V32.2399H40.8559C41.1151 32.2415 41.3694 32.169 41.5888 32.0307C41.8081 31.8925 41.9833 31.6943 42.0936 31.4597C42.2039 31.2251 42.2448 30.9638 42.2113 30.7067C42.1779 30.4496 42.0715 30.2074 41.9049 30.0089Z"
                  fill="#F0F2F8"
                />
              </svg>
            )}
            <p className="drag-and-drop-text">Drag &amp; Drop</p>
            <div className="d-flex align-items-center">
              <label className="custom-file-upload">
                {siMappingSelectedFile ? (
                  <div className="d-flex flex-column align-items-center">
                    <div className="position-relative d-flex align-items-center mt-2">
                      <p className="browser-text">Change File</p>
                      <div
                        className="cancel-upload"
                        onClick={handleSiMappingFileCancel}
                      >
                        <i className="bi bi-x"></i>
                      </div>
                      <p className="selected-file-name">
                        {" "}
                        {siMappingSelectedFile && siMappingSelectedFile.name}
                      </p>
                    </div>
                    <button
                      onClick={handleSiMappingUpload}
                      className={`btn btn-sm btn-upload-file mt-3`}
                      disabled={isSubmitDisabledSiMapping}
                    >
                      <span>Upload</span>
                    </button>
                  </div>
                ) : (
                  <div className="text-center">
                    <div className="d-flex align-items justify-content-center center mb-3">
                      <>
                        {isActive ? (
                          <>
                            <p className="your-file-text">Your file or&nbsp;</p>
                            <p className="browser-text">Browse</p>
                          </>
                        ) : (
                          <>
                            <p
                              className="your-file-text"
                              style={{ cursor: "no-drop" }}
                            >
                              Your file or&nbsp;
                            </p>
                            <p
                              className="your-file-text"
                              style={{ color: "#00bda9", cursor: "no-drop" }}
                            >
                              Browse
                            </p>
                          </>
                        )}
                      </>
                    </div>
                    <p className="upload-text-small">
                      SI mapping file should have exactly two columns with
                      headers <b>“Primary_Themes”</b> and <b>“SI”</b>.
                    </p>
                    <p className="upload-text-extra-small">
                      Please refrain from making alterations to the
                      “Primary_Themes” column in template file.
                    </p>
                  </div>
                )}
                {isActive ? (
                  <input
                    type="file"
                    accept=".xlsx"
                    onChange={handleSiMappingFileChange}
                    onBlur={handleSiMappingFileCancel}
                  />
                ) : (
                  ""
                )}
              </label>
            </div>
            {siMappingFileError && (
              <p className="error-message">{siMappingFileError}</p>
            )}
          </div>
          <p
            className={
              isActive
                ? "download-sample-data"
                : "download-sample-data disabled"
            }
          >
            {isActive ? (
              <>
                <a
                  href={SiMappingSampleFile}
                  download="Sample-SI-Mapping"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here
                </a>{" "}
                to download the sample template file.
              </>
            ) : (
              <>
                <span>Click here</span> to download the sample template file.
              </>
            )}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        {submitBtnActive ? (
          <>
            <button
              className={`btn btn-sm btn-submit`}
              onClick={() => getDownloadableFile()}
            >
              <i className="bi bi-floppy"></i>
              <span className="ml-1">Submit</span>
            </button>
          </>
        ) : (
          <>
            <button className={`btn btn-sm btn-submit disabled`}>
              <i className="bi bi-floppy"></i>
              <span className="ml-1">Submit</span>
            </button>
          </>
        )}
      </div>
      {apiLoading && (
        <ApiLoader apiLoaderInfo={apiLoaderInfo} oopsLoaderInfo={oopsLoader} />
      )}
    </div>
  );
}

export default MvocPrimary;
