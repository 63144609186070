import React from "react";
import Logo from "../../assets/images/navigator-logo.png";
import "./footer.css";
export default function Footer() {
  return (
    <div className="footer-sec">
      <p>Powered by</p>
      <div className="ms-1">
        <img style={{ width: "100px" }} src={Logo} alt="" />
      </div>
    </div>
  );
}
