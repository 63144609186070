import React, { useState } from "react";
import "./download.css";

export default function TableGrid(props) {
  const data = props?.requiredData?.df_table || [];
  const tableHeaderArray = [
    "SI",
    "Primary Themes",
    "COUNT",
    "Primary theme keyword",
  ];

  const tableDataWithReapeatedSI = [];
  const groupedData = {};

  // created 2d array for table
  for (let key in data.Count) {
    const si = data.SI[key];
    const count = data.Count[key];
    const primaryTheme = data.Primary_Themes[key];
    const primaryThemeKeyword = data.Primary_Themes_Keywords[key];

    if (!groupedData[si]) {
      groupedData[si] = [];
    }

    groupedData[si].push([
      { value: si },
      { value: primaryTheme },
      { value: count },
      { value: primaryThemeKeyword },
    ]);
  }

  // Combine grouped data into a single array
  for (const si in groupedData) {
    tableDataWithReapeatedSI.push(...groupedData[si]);
  }

  // in 2d arrayfor reapeated si are combined and count added for rowspan
  const tableData = tableDataWithReapeatedSI;

  const seenValues = {};

  for (let i = 0; i < tableData.length; i++) {
    const value = tableData[i][0].value;

    if (!seenValues.hasOwnProperty(value)) {
      seenValues[value] = true;
    } else {
      // If the value is already seen, remove the first object in the subarray
      if (tableData[i - 1].length === 4) {
        let rep = tableData[i - 1][0].value;
        let repCount = 0;
        for (let h = 0; h < tableDataWithReapeatedSI.length; h++) {
          if (tableDataWithReapeatedSI[h][0].value === rep) {
            repCount++;
          }
        }
        tableData[i - 1][0].count = repCount;
      }
      tableData[i].splice(0, 1);
    }
  }

  // console.log('table data', tableData);

  return (
    <div className="table-part-mvoc table-body-respo">
      <table className="table table-bordered mb-0">
        <thead>
          <tr className="table-header-st">
            <th scope="col" className="table-hd" key={1}>
              {"Strategic Imperatives (SI)"}
            </th>
            <th scope="col" className="table-hd" key={2}>
              {"Primary Themes"}
            </th>
            <th scope="col" className="table-hd" key={4}>
              {"Count"}
            </th>
            <th scope="col" className="table-hd extra-large-column-wd" key={3}>
              {"Frequent Keywords"}
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} rowSpan={cell.count}>
                  {cell.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
