import React, { useEffect, useState } from "react";
import loaderImgOne from "../../assets/loader/loader-img-1.png";
import loaderImgTwo from "../../assets/loader/loader-img-2.png";
import loaderImgThree from "../../assets/loader/loader-img-3.png";
import loaderImgFour from "../../assets/loader/loader-img-4.png";
import loaderImgFive from "../../assets/loader/loader-img-5.png";
import "./ApiLoader.css";

export default function ApiLoader(props) {
  // console.log('Loder file props', props.apiLoaderInfo);

  // console.log('Oops file props', props.oopsLoaderInfo);

  const [loaderProcessOne, setLoaderProcessOne] = useState(false);
  const [loaderProcessTwo, setLoaderProcessTwo] = useState(false);
  const [loaderProcessThree, setLoaderProcessThree] = useState(false);
  const [loaderProcessFour, setLoaderProcessFour] = useState(false);
  const [loaderProcessFive, setLoaderProcessFive] = useState(false);

  const [firstLoadingComplete, setfirstLoadingComplete] = useState(false);
  const [secondLoadingComplete, setsecondLoadingComplete] = useState(false);
  const [thirdLoadingComplete, setthirdLoadingComplete] = useState(false);
  const [fourthLoadingComplete, setfourthLoadingComplete] = useState(false);
  const [fifthLoadingComplete, setfifthLoadingComplete] = useState(false);

  function firstLoading() {
    setTimeout(() => {
      setfirstLoadingComplete(true);
      secondLoading();
    }, 25000);
    setLoaderProcessOne(true);
  }

  function secondLoading() {
    setTimeout(() => {
      setsecondLoadingComplete(true);
      thirdLoading();
    }, 25000);
    setLoaderProcessTwo(true);
  }

  function thirdLoading() {
    setTimeout(() => {
      setthirdLoadingComplete(true);
      fourthLoading();
    }, 25000);
    setLoaderProcessThree(true);
  }

  function fourthLoading() {
    setTimeout(() => {
      setfourthLoadingComplete(true);
      fifthLoading();
    }, 25000);
    setLoaderProcessFour(true);
  }

  function fifthLoading() {
    setTimeout(() => {
      if (props.apiLoaderInfo) {
        setfifthLoadingComplete(true);
      }
    }, 10000);
    setLoaderProcessFive(true);
  }

  useEffect(() => {
    firstLoading(secondLoading);
  }, []);

  return (
    <div className="spinner-loader">
      {/* <img className='loader-css' src={GifForLoader} alt="" /> */}
      <div className="loader-body">
        {props.oopsLoaderInfo ? (
          <>
            <div className="oops-details-bg ">
              <h3>
                <span className="">Oops! </span> Something went wrong.
              </h3>
              <p>Please try again</p>
            </div>
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-between">
            <div className={loaderProcessOne ? "icon-body" : "icon-body-dis"}>
              <div className="icon-sp">
                <img
                  src={loaderImgOne}
                  alt="loader-image-one"
                  className="img-fluid icon-sz"
                />
              </div>
              <div className="running-loader">
                <div
                  className={
                    loaderProcessOne
                      ? firstLoadingComplete
                        ? "progress-bar-loader bar-loader-complete"
                        : "progress-bar-loader indeterminate"
                      : "progress-bar-loader"
                  }
                ></div>
              </div>
              <h4>Validating Uploaded file schema</h4>
            </div>

            <div className={loaderProcessTwo ? "icon-body" : "icon-body-dis"}>
              <div className="icon-sp">
                <img
                  src={loaderImgTwo}
                  alt="loader-image-one"
                  className="img-fluid icon-sz"
                />
              </div>
              <div className="running-loader">
                <div
                  className={
                    loaderProcessTwo
                      ? secondLoadingComplete
                        ? "progress-bar-loader bar-loader-complete"
                        : "progress-bar-loader indeterminate"
                      : "progress-bar-loader"
                  }
                ></div>
              </div>
              <h4>Dynamically generating prompt</h4>
            </div>

            <div className={loaderProcessThree ? "icon-body" : "icon-body-dis"}>
              <div className="icon-sp">
                <img
                  src={loaderImgThree}
                  alt="loader-image-one"
                  className="img-fluid icon-sz"
                />
              </div>
              <div className="running-loader">
                <div
                  className={
                    loaderProcessThree
                      ? thirdLoadingComplete
                        ? "progress-bar-loader bar-loader-complete"
                        : "progress-bar-loader indeterminate"
                      : "progress-bar-loader"
                  }
                ></div>
              </div>
              <h4>Calling Open AI API</h4>
            </div>

            <div className={loaderProcessFour ? "icon-body" : "icon-body-dis"}>
              <div className="icon-sp">
                <img
                  src={loaderImgFour}
                  alt="loader-image-one"
                  className="img-fluid icon-sz"
                />
              </div>
              <div className="running-loader">
                <div
                  className={
                    loaderProcessFour
                      ? fourthLoadingComplete
                        ? "progress-bar-loader bar-loader-complete"
                        : "progress-bar-loader indeterminate"
                      : "progress-bar-loader"
                  }
                ></div>
              </div>
              <h4>Transforming the responses</h4>
            </div>

            <div className={loaderProcessFive ? "icon-body" : "icon-body-dis"}>
              <div className="icon-sp">
                <img
                  src={loaderImgFive}
                  alt="loader-image-one"
                  className="img-fluid icon-sz"
                />
              </div>
              <div className="running-loader">
                <div
                  className={
                    loaderProcessFive
                      ? fifthLoadingComplete
                        ? "progress-bar-loader bar-loader-complete"
                        : "progress-bar-loader indeterminate"
                      : "progress-bar-loader"
                  }
                ></div>
              </div>
              <h4>Generating the final files</h4>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
