import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header/Header";
import Footer from "../../layouts/Footer/Footer";
import MvocPrimary from "../../components/MvocPrimary/MvocPrimary";
import MvocBlurView from "../../components/MvocBlurView/MvocBlurView";
import { useCookies } from "react-cookie";
import { userLogginApiService } from "../../services/homePageService";
import JSCookie from 'js-cookie';

function HomePage() {
  // Set cookies
  const [cookies] = useCookies(["inizionavigator"]);

  const FetchToken = () => {
    if (!JSCookie.get('inizionavigator')) return null;
    const token = JSON.parse(JSCookie.get('inizionavigator')).token;
    return token;
};

// var for token
const Acctoken = FetchToken();

  if (cookies.inizionavigator) {
    // Set user ID to the localstorage for the future use
    localStorage.setItem(
      "loginUserId",
      cookies.inizionavigator
        ? cookies.inizionavigator.userLoginName
            ?.split(" ")
            ?.join(".")
            .toLocaleLowerCase()
        : "",
    );
    // Set user blob folder to the localstorage for the future use
    localStorage.setItem(
      "blobFolder",
      cookies.inizionavigator
        ? cookies.inizionavigator.userLoginName
            ?.split(" ")
            ?.join("_")
            .toLocaleLowerCase()
        : "",
    );
    // Set user user email to the localstorage for the future use
    localStorage.setItem(
      "loginUserEmailId",
      cookies.inizionavigator ? cookies.inizionavigator.userLoginEmail : "",
    );
  } else if (window.location.href === "http://localhost:3000/") {
    // Set user ID to the localstorage for the future use
    localStorage.setItem("loginUserId", "Demo User");
    // Set user blob folder to the localstorage for the future use
    localStorage.setItem("blobFolder", "Demo_User");
    // Set user user email to the localstorage for the future use
    localStorage.setItem("loginUserEmailId", "demo@inizio.health");
  } else {
    // localStorage.clear();
    window.location.href = process.env.REACT_APP_LOGIN_URL;
  }

  const user_name = localStorage.getItem("loginUserId");

  // Define state for store user login and waiting user API data
  const [userLoginData, setUserLoginData] = useState({});

  // Define state for first User login and wating user
  const [firstUserAvailable, setFirstUserAvailable] = useState(false);

  const checkUserIsLoginRank = async(postBodyObj) => {

    try {
      const response = await userLogginApiService(postBodyObj);
      console.log(response,"homeres");

      if (response.status != 201) {
        throw new Error("Network response was not ok.");
      } else {
        console.log("User Logout Successfully", response?.data);
        setUserLoginData(response?.data);
      }
    } catch (error) {
      console.error(
        '"login auth API error: "There was a problem with the fetch operation for the call API CASE:logout',
        error,
      );
    }


    // let checkedUserLoggeInApiUrl =
    //   "https://user-manage-parameter.azurewebsites.net/user_manage_parameter";
    // // Configuration for the fetch request
    // const checkUserRequestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(postBodyObj),
    // };
    // // Making the POST request using fetch information about user rank, logging and check option
    // fetch(checkedUserLoggeInApiUrl, checkUserRequestOptions)
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok.");
    //     }
    //     return response.json(); // Parse response JSON data
    //   })
    //   .then((userLoginDataObj) => {
    //     setUserLoginData(userLoginDataObj);
    //   })
    //   .catch((error) => {
    //     console.error(
    //       "There was a problem with the fetch operation for the call API CASE:Login:",
    //       error,
    //     );
    //   });
  };

  // Fetch API data for Login User; Data to be sent in the check user is login and it's rank
  useEffect(() => {
    // Modified object body for login
    let loggInUserData = {
      username: user_name,
      action: "login",
      token: 'c4a54dea-eb4e-4846-b3b7-2891e531d4e0',
    };
    if (user_name) {
      // call API function for login details
      checkUserIsLoginRank(loggInUserData);
    }
  }, []);

  useEffect(() => {
    if (userLoginData.firstRankuserrank) {
      console.log(userLoginData,"userLoginDatauserLoginData")
      setFirstUserAvailable(true);
    } else {
      setFirstUserAvailable(false);
    }
  }, [userLoginData]);

  // console.log('login data', userLoginData);

  return (
    <div className="page-wrapper hp-bg toast-msg">
      <Header />
      <div className="center-section d-flex align-items-center justify-content-center flex-column">
        <div className="d-flex flex-column justify-content-center welcome-msg">
          <p>Enter the details and upload relvant documents to start!</p>
        </div>
        {firstUserAvailable ? (
          <MvocBlurView userLoginData={userLoginData} />
        ) : (
          <MvocPrimary userLoginData={userLoginData} />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
