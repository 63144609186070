import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import TableGrid from "./TableGrid";
import WheelChart from "../charts/WheelChart";
// import SunburstChart from "../charts/SunburstChart";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ReplyIcon from "@mui/icons-material/Reply";
import "./download.css";
import { userLogginApiService } from "../../services/homePageService";
import { downloadFileService } from "../../services/downloadPageService";
import JSCookie from 'js-cookie';

function DownloadFile() {
  // Use Navigation
  const navigate = useNavigate();

  const backToHomePage = () => {
    navigate("/voc-input");
  };

  const FetchToken = () => {
    if (!JSCookie.get('inizionavigator')) return null;
    const token = JSON.parse(JSCookie.get('inizionavigator')).token;
    return token;
 };
 const Acctoken = FetchToken();

  // Define Variable
  const user_name = localStorage.getItem("loginUserId");
  // Use state data, redireact from upload file to download and chart screen
  const { state } = useLocation();
  // Define state for check Radio button state toggle option
  const [checkedRadioBtn, setCheckedRadioBtn] = useState(true);
  // Define state for check button download state
  const [isFileDownloaded, setIsFileDownloaded] = useState(false);
  // This funtion use for check radio button state to update button state
  const changeHandler = () => {
    setCheckedRadioBtn(!checkedRadioBtn);
  };

  const userBlobFolderName = localStorage.getItem("blobFolder");
  const currentData = state;
  const data = state.df_fig;
  // Create a new object for the required format
  const requiredData = {
    df_fig: {
      SI: {},
    },
    file_name1: currentData.file_name1,
    file_name2: currentData.file_name2,
  };

  // check download button state, is download click or not
  const checkDownloadFileHandler = () => {
    setIsFileDownloaded(true);
  };

  // API function to check user list
  const checkUserIsLoginRank = async(postBodyObj) => {
    // let checkedUserLoggeInApiUrl =
    //   "https://user-manage-parameter.azurewebsites.net/user_manage_parameter";
   // Configuration for the fetch request
    // const checkUserRequestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(postBodyObj),
    // };

    try {
      const response = await userLogginApiService(postBodyObj);
      if (response.status != 201) {
        throw new Error("Network response was not ok.");
      } else {
        console.log("User Logout Successfully", response?.data);
      }
    } catch (error) {
      console.error(
        '"login auth API error: "There was a problem with the fetch operation for the call API CASE:logout',
        error,
      );
    }

   // Making the POST request using fetch information about user rank, logging and check option
    // fetch(checkedUserLoggeInApiUrl, checkUserRequestOptions)
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok.");
    //     }
    //     return response.json(); // Parse response JSON data
    //   })
    //   .then((logoutMsg) => {
    //     console.log("User Logout Successfully", logoutMsg);
    //   })
    //   .catch((error) => {
    //     console.error(
    //       '"login auth API error: "There was a problem with the fetch operation for the call API CASE:logout',
    //       error,
    //     );
    //   });
  };

  useEffect(() => {
    if (isFileDownloaded) {
      console.log("download complete, true");
      // Logout user after 5 minuets, if user download the file.
      setTimeout(() => {
        let logoutUserReqBody = {
          username: user_name,
          action: "logout",
          token: 'c4a54dea-eb4e-4846-b3b7-2891e531d4e0',
        };
        // logout user after 30 minuts auto, if user use the application
        checkUserIsLoginRank(logoutUserReqBody);
      }, 30000000); // set time for 5 minutes: 300000,
    }
  }, [isFileDownloaded]);

  // Loop through the "SI" and "Primary_Themes" objects in the current data
  for (const key in currentData.df_fig.SI) {
    const siValue = currentData.df_fig.SI[key];
    const theme = currentData.df_fig.Primary_Themes[key];
    const count = currentData.df_fig.Count[key];

    if (!(siValue in requiredData.df_fig.SI)) {
      requiredData.df_fig.SI[siValue] = {
        count: 0,
      };
    }

    if (!(theme in requiredData.df_fig.SI[siValue])) {
      requiredData.df_fig.SI[siValue][theme] = count;
    } else {
      requiredData.df_fig.SI[siValue][theme] += count;
    }

    requiredData.df_fig.SI[siValue].count += count;
  }

  // function to call at download of pivot file download
  const downloadPivotFileHandler=async()=>{
    try {
      const blobPath=`${state.file_name2}`;
      const response=await downloadFileService(blobPath);
      window.open(response?.data);
    } catch (error) {
      console.log(error,"error to download pivot file");
    }
  }


  // function to call for download of response file
  const downloadResponseFileHandler=async()=>{
    try {
      const blobPath=`${state.file_name1}`;
      const response=await downloadFileService(blobPath);
      window.open(response?.data);
      setIsFileDownloaded(true);
    } catch (error) {
      console.log(error,"error to download response file");
    }
  }

  return (
    <>
      <div className="download-sec">
        <div className="d-flex align-items-center tab-heading-sec">
          <div className="">
            <button className="btn logout-btn back-btn-sp">
              <ReplyIcon />
              <p className="ml-5p" onClick={backToHomePage}>
                Back
              </p>
            </button>
          </div>
          <div className="switches-container">
            <input
              type="radio"
              id="switchTable"
              name="switchPlan"
              value="tableView"
              checked={checkedRadioBtn ? true : false}
              onChange={changeHandler}
            />
            <input
              type="radio"
              id="switchDashboard"
              name="switchPlan"
              value="dashboardView"
              checked={!checkedRadioBtn ? true : false}
              onChange={changeHandler}
            />

            <label htmlFor="switchTable">
              <div className="d-flex align-items-center justify-content-center">
                <DashboardIcon className="switch-btn-icon-m-r" />
                <span className="table-view">Dashboard View</span>
              </div>
            </label>
            <label htmlFor="switchDashboard">
              <div className="d-flex align-items-center justify-content-center">
                <TableChartOutlinedIcon className="switch-btn-icon-m-r" />
                <span className="table-view">Table View</span>
              </div>
            </label>
            <div className="switch-wrapper">
              <div className="switch">
                <div className="d-flex align-items-center justify-content-center">
                  <DashboardIcon className="switch-btn-icon-m-r" />
                  <span className="table-view">Dashboard View</span>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <TableChartOutlinedIcon className="switch-btn-icon-m-r" />
                  <span className="table-view">Table View</span>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div
              className="btn logout-btn download-btn-sp"
              onClick={downloadResponseFileHandler}
            >
              <FileDownloadIcon />
              <p className="ml-1">Output File</p>
            </div>
          </div>
        </div>

        {/* check condition for toggle option  */}
        {checkedRadioBtn ? (
          <div className="row">
            <div className="col">
              <div className="wheel-chart-section">
                <div className="d-flex align-items-center justify-content-between p-2">
                  <h4 className="input-label">Customer Theme Insights</h4>
                </div>
                {/* <SunburstChart requiredData={state} /> */}
                <WheelChart requiredData={state} />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col h-100">
              <div className="table-sec">
                <div className="d-flex align-items-center justify-content-between p-2">
                  <h4 className="table-title-class input-label">
                    Customer Theme Insights
                  </h4>
                  <div
                    className="btn btn download-btn ml-2"
                    onClick={downloadPivotFileHandler}
                  >
                    <FileDownloadIcon />
                  </div>
                </div>
                <TableGrid requiredData={state} />
              </div>
            </div>
          </div>
        )}
        {/* #END  */}
      </div>
    </>
  );
}

export default DownloadFile;
