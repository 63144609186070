import React, { useLayoutEffect, useState } from "react";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default function WheelChart(props) {
  // Set state for click on the graph for inner circle and outer circle
  const [innerCircle, setInnerCircle] = useState(false);
  const [outerCircle, setOuterCircle] = useState(false);

  const data = props?.requiredData?.df_fig || [];

  
  const totalCount = Object.values(data.Count).reduce(
    (sum, current) => sum + current,
    0,
  );

  // console.log(data);
  // Extract the keys from one of the objects (e.g., "Count")
  const keys = Object.keys(data["Count"]);

  /* keys in case of run-note-book node js api  */
  // const keys=data["Count"]||[];
 
  // Convert the JSON data into an array of objects
  const arrayOfObjects = keys.map((key) => ({
    Count: data["Count"][key],
    Primary_Themes: data["Primary_Themes"][key],
    SI: data["SI"][key],
    frequent_words: data["data"][key],
  }));

  /* arrayOfObjects in case of run-note-book node js api  */
  // const arrayOfObjects = keys.map((key,index) => ({
  //   Count: data["Count"][index],
  //   Primary_Themes: data["Primary_Themes"][index],
  //   SI: data["SI"][index],
  //   frequent_words: data["data"][index]=="NA"?[] :data["data"][index]
  // }));


  const result = [];
  const grouped = {};

  arrayOfObjects.forEach((item, index) => {
    const primaryThemeChildren = item?.frequent_words?.map((keyword) => ({
      name: keyword.name,
      value: keyword.value,
      count: "",
      count_text: "",
      colon_text: "",
      percentage: "",
      line_text: "",
      circle: "uperouter",
    }));

    if (grouped[item.SI]) {
      const percentage = ((item.Count / totalCount) * 100).toFixed(0);
      const percentageStr =
        percentage % 1 === 0
          ? percentage.toString().replace(/\.00$/, "")
          : percentage.toString();
      grouped[item.SI].children.push({
        name: item.Primary_Themes,
        p_name: item.Primary_Themes,
        count: item.Count,
        count_text: "Count: ",
        colon_text: ": ",
        line_text: "\n",
        percentage: percentageStr + "%,",
        // value: item.Count,
        circle: "outer",
        children: primaryThemeChildren,
      });
    } else {
      const percentage = ((item.Count / totalCount) * 100).toFixed(0);
      const percentageStr =
        percentage % 1 === 0
          ? percentage.toString().replace(/\.00$/, "")
          : percentage.toString();
      const newItem = {
        name: item.SI,
        // siCount: (item.SI.charAt(0) === 'N') ? `NA,` : `SI${item.SI.charAt(0)},`,
        siCount: item.SI,
        percentage: percentageStr + "%,",
        count: item.Count,
        count_text: "Count: ",
        colon_text: ": ",
        circle: "inner",
        line_text: "\n",
        // value: item.Count,
        children: [
          {
            name: item.Primary_Themes,
            p_name: item.Primary_Themes,
            percentage: percentageStr + "%,",
            count: item.Count,
            count_text: "Count: ",
            colon_text: ": ",
            line_text: "\n",
            // value: item.Count,
            circle: "outer",
            children: primaryThemeChildren,
          },
        ],
      };
      grouped[item.SI] = newItem;
      result.push(newItem);
    }
  });

  result.forEach((element) => {
    let child = element.children;
    let sumOfper = 0;
    let sumOfCount = 0;
    // Calculate the sum of counts for child elements
    child.forEach((ele) => {
      // console.log(ele.percentage.split("%,").join(""));
      sumOfper = sumOfper + Number(ele.percentage.split("%,").join(""));
      sumOfCount = sumOfCount + Number(ele.count);
    });
    // Update the parent's count based on the sum
    element.percentage = sumOfper + "%,";
    element.count = sumOfCount;
    element.ptPer = 100 + "%,";
    child.forEach((ele) => {
      ele.ptPer = ((Number(ele.count) / sumOfCount) * 100).toFixed(0);
      ele.ptPer =
        ele.ptPer % 1 === 0
          ? ele.ptPer.toString().replace(/\.00$/, "") + "%,"
          : ele.ptPer.toString() + "%,";
      ele.ptPer2 = 100;
      ele.ptPer2 =
        ele.ptPer2 % 1 === 0
          ? ele.ptPer2.toString().replace(/\.00$/, "") + "%,"
          : ele.ptPer2.toString() + "%,";
    });
    // Update the parent_count for child elements
    child.forEach((ele) => {
      ele.parent_count = element.count;
    });
  });

  //   console.log("result ", result);

  useLayoutEffect(() => {
    let root = am5.Root.new("threeLevelWeelChart");
    root._logo.dispose();
    root.setThemes([am5themes_Animated.new(root)]);
    // Create wrapper container
    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        //layout: root.verticalLayout
      }),
    );
    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    });
    // Create series
    let series = container.children.push(
      am5hierarchy.Sunburst.new(root, {
        singleBranchOnly: true,
        downDepth: 3,
        initialDepth: 3,
        topDepth: 1,
        outerRadius: 100,
        innerRadius: am5.percent(26),
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
      }),
    );

    // Add title
    let title = container.children.push(
      am5.Label.new(root, {
        text: `Total\nThemes\nCount\n[#63bdc5][#00bda9]${totalCount.toString()}[/]`,
        textAlign: "center", // Center horizontally
        textVerticalAlign: "middle", // Center vertically
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
        fontSize: 16,
        fontWeight: "500",
        fill: am5.color(0x385d63),
      }),
    );

    localStorage.setItem("innerCliked", "inner one series view");
    localStorage.setItem("outerClicked", "outer all series view");

    series.nodes.template.events.on("click", (event) => {
      if (event.target.dataItem.dataContext.circle === "inner") {
        let value = event.target.dataItem.dataContext.count;
        if (localStorage.getItem("innerCliked") === "inner one series view") {
          title.set(
            "text",
            `Total Themes\nCount\n[#63bdc5][#00bda9]${value}[/]`,
          );
          series.labels.template.set("text", `{name}\n {ptPer} {count}`);
          series.nodes.template.set(
            "tooltipText",
            `{category}{colon_text}{ptPer}{line_text}{count_text}{count}`,
          );

          localStorage.setItem("innerCliked", "inner all series view");
        } else {
          title.set(
            "text",
            `Total Themes\nCount\n[#63bdc5][#00bda9]${totalCount.toString()}[/]`,
          );
          series.labels.template.set("text", `{name}\n{percentage} {count}`);
          series.nodes.template.set(
            "tooltipText",
            `{category}{colon_text}{percentage}{line_text}{count_text}{count}`,
          );

          localStorage.setItem("innerCliked", "inner one series view");
        }
      }
    });

    series.nodes.template.events.on("click", (event) => {
      if (event.target.dataItem.dataContext.circle === "outer") {
        let value = event.target.dataItem.dataContext.count;
        let parentCount = event.target.dataItem.dataContext.parent_count;

        if (localStorage.getItem("outerClicked") === "outer one series view") {
          // console.log("click for outer, IF 'VIEW ALL OUTER'");
          localStorage.setItem("outerClicked", "outer all series view");
          title.set(
            "text",
            `Total Themes\nCount\n[#63bdc5][#00bda9]${parentCount}[/]`,
          );
          series.labels.template.set("text", `{name}\n{ptPer} {count}`);
          series.nodes.template.set(
            "tooltipText",
            `{category}{colon_text}{ptPer}{line_text}{count_text}{count}`,
          );
          localStorage.setItem("innerCliked", "inner all series view");
        } else {
          // console.log("click for outer, else 'VIEW ONE'");
          localStorage.setItem("outerClicked", "outer one series view");
          title.set(
            "text",
            `Total Themes\nCount\n[#63bdc5][#00bda9]${value}[/]`,
          );
          series.labels.template.set("text", `{name}\n {ptPer2} {count}`);
          series.nodes.template.set(
            "tooltipText",
            `{category}{colon_text}{ptPer2}{line_text}{count_text}{count}`,
          );

          localStorage.setItem("innerCliked", "inner all series view");
        }
      }
    });

    series.labels.template.setAll({
      paddingBottom: 10,
      fill: am5.color("#ffffff"),
      textAlign: "center",
      width: "auto",
      text: `{name}\n{percentage} {count}`,
      ellipsis: "..",
      breakWords: true,
      oversizedBehavior: "truncate",
      fontSize: 12,
    });

    series.nodes.template.set(
      "tooltipText",
      `{category}{colon_text}{percentage}{line_text}{count_text}{count}`,
    );

    let data = [
      {
        name: "medaffairs",
        nodeSettings: { fill: am5.color(0xaaaaaa) },
        children: result,
      },
    ];

    series.data.setAll(data);
    series.set("selectedDataItem", series.dataItems[0]);

    series.hide((dataItem) => {
      // You can specify a condition to filter out the last layer of data
      // For instance, if you want to remove items with no children (leaf nodes):
      return !dataItem.children.length;
    });

    // Make stuff animate on load
    series.appear(1000, 100);
    return () => root.dispose();
  }, [data]);
  return <div id="threeLevelWeelChart"> </div>;
}
