import Header from "../../layouts/Header/Header";
import Footer from "../../layouts/Footer/Footer";
import DownloadFile from "../../components/Download/DownloadFile";
import { useCookies } from "react-cookie";

function DownloadFiles() {
  return (
    <div className="page-wrapper hp-bg">
      <Header />
      <DownloadFile />
      <Footer />
    </div>
  );
}

export default DownloadFiles;
