import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage.js";
import LogoutPage from "./pages/LogoutPage/LogoutPage.js";
import Homepage from "./pages/HomePage/Homepage";
import DownloadFiles from "./pages/DownloadPage/DownloadFiles";

// Route for SSO-frontend for the protected routes only
import ProtectedRoute from "./components/ProtectedRoute/index.jsx";
import { provider } from "./services/provider.js";
import { useCookies } from 'react-cookie';
import { refreshTokenApi, userLogginApiService } from "./services/homePageService.js";

function App() {
  // Remove element from the local storage
  const [cookies, setCookie, removeCookie] = useCookies(['inizionavigator']);

  const UserName =
    cookies.inizionavigator && cookies.inizionavigator.userLoginName;
  const UserEmail =
    cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;
  const token = cookies.inizionavigator && cookies.inizionavigator.token;

  let experieTimeStamp = cookies.inizionavigator && cookies.inizionavigator.exp;

  const user_name = localStorage.getItem("loginUserId");

  useEffect(() => {
    const fetchAuthCheck = async () => {
      try {
        const response = await provider.get('/api/auth-check');
        console.log("🚀 ~ response:", response)
      } catch (error) {
        console.log(error);
        if (cookies.inizionavigator) {
          removeCookie(`inizionavigator`, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          })
        };
        localStorage.clear();
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    }

    if (cookies?.inizionavigator?.refreshToken) {
      const tokenExpiry = cookies?.inizionavigator?.exp;
      const currentTime = Date.now();


      const timeDifference = tokenExpiry * 1000 - currentTime - 3 * 60 * 1000;

      setTimeout(() => {
        (async () => {
          try {
            const refreshToken = cookies?.inizionavigator?.refreshToken;
            const payload = {
              refresh_token: refreshToken,
            };

            const response = await refreshTokenApi(payload, token);
            if (response?.is_success === true) {
              setCookie(`inizionavigator`, response?.responseCode, {
                path: '/',
                domain: process.env.REACT_APP_COOKIE_DOMAIN,
              });
            } else {
              const payload = {
                username: user_name,
                action: "logout",
                token: 'c4a54dea-eb4e-4846-b3b7-2891e531d4e0',
              };
              await userLogginApiService(payload);
              if (cookies.inizionavigator) {
                console.log(cookies.inizionavigator, "cookies.inizionavigator later")
                removeCookie(`inizionavigator`, {
                  path: "/",
                  domain: process.env.REACT_APP_COOKIE_DOMAIN,
                })
              }
              window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            const payload = {
              username: user_name,
              action: "logout",
              token: 'c4a54dea-eb4e-4846-b3b7-2891e531d4e0',
            };
            await userLogginApiService(payload);
            localStorage.clear();
            if (cookies.inizionavigator) {
              console.log(cookies.inizionavigator, "cookies.inizionavigator later")
              removeCookie(`inizionavigator`, {
                path: "/",
                domain: process.env.REACT_APP_COOKIE_DOMAIN,
              })
            }
            // await LogoutApi(token);
            window.location.href = process.env.REACT_APP_LOGIN_URL;
          }
        })()
      }, timeDifference);
    }

    fetchAuthCheck()
  }, [cookies?.inizionavigator, token, setCookie, removeCookie])


  return (
    <>
      <Routes>
        {/* <Route exact path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/homepage" element={<Homepage />} />
        <Route path="/download" element={<DownloadFiles />} /> */}
        {/* <Route
          exact
          path="/"
          element={<ProtectedRoute element={<LoginPage />} />}
        /> */}
        <Route
          path="/voc-input"
          element={<ProtectedRoute element={<Homepage />} />}
        />
        <Route
          exact
          path="/download"
          element={<ProtectedRoute element={<DownloadFiles />} />}
        />
        {/* <Route
          exact
          path="/logout"
          element={<ProtectedRoute element={<LogoutPage />} />}
        /> */}
      </Routes>
    </>
  );
}

export default App;
